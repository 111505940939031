import { graphqlClient } from "../../../client";
import mutationDocument from "./moveWishlistItemMutation.graphql";
import type {
  CartWishlistTupleType,
  InputMoveWishlistItemTypeExtension,
  Mutations,
  MutationsMoveWishlistItemArgs,
} from "@/core/api/graphql/types";

export async function moveWishlistItem(payload: InputMoveWishlistItemTypeExtension): Promise<CartWishlistTupleType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "moveWishlistItemTL">>,
    MutationsMoveWishlistItemArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });
  return data!.moveWishlistItemTL;
}
