import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import queryDocumentForWishlistById from "./getWishlist.graphql";
import queryDocumentForWishlistByName from "./getWishlistByName.graphql";
import type { Query, QueryWishlistArgs, QueryWishlistByNameArgs, WishlistType } from "@/core/api/graphql/types";

export async function getWishList(listId: string): Promise<WishlistType> {
  const { cultureName } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "wishlist">>, QueryWishlistArgs>({
    query: queryDocumentForWishlistById,
    variables: {
      cultureName,
      listId,
    },
  });

  return data.wishlist;
}

export async function getWishListByName(name: string, customerId: string): Promise<WishlistType> {
  const { cultureName } = globals;
  const { data } = await graphqlClient.query<Required<Pick<Query, "wishlistByName">>, QueryWishlistByNameArgs>({
    query: queryDocumentForWishlistByName,
    variables: {
      cultureName,
      name,
      customerId,
    },
  });

  return data.wishlistByName;
}
